import React from "react"
import { events } from "../data/events"
import moment from "moment"
import { Link } from "gatsby"

export default function event() {
  return (
    <>
      <div className="mb-5 px-0 px-md-5 px-lg-5 px-xl-5">
        {/* <h1 className="heading mb-5">UPCOMING EVENTS</h1> */}
        <div className="row text-center">
          {events.map(
            ({
              link,
              city,
              location,
              datetime,
              eventName,
              image,
              highlight,
            }) => {
              console.log(link)
              console.log(highlight)
              const date =
                typeof datetime === "object" ? datetime.timestamp : datetime
              const format =
                typeof datetime === "object" ? datetime.format : "DD MMMM, YYYY"

              return (
                <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                  <Link to={link}>
                    <div class="flip-card">
                      <div class="flip-card-inner">
                        <div class="flip-card-front">
                          <img
                            src={image}
                            className="img-fluid"
                            style={{ margin: 0 }}
                            alt={``}
                          />
                          <h1 className={`card-location ${highlight}`}>
                            {city}
                          </h1>
                        </div>
                        <div class="flip-card-back">
                          {eventName && (
                            <h1
                              className="event-font-style"
                              style={{ margin: 0 }}
                            >
                              {eventName}
                            </h1>
                          )}
                          {location && (
                            <h1
                              className="event-font-style"
                              style={{ margin: 0 }}
                            >
                              {location}
                            </h1>
                          )}

                          {/* <h1 className="event-font-style" style={{ margin: 0 }}>
                        {moment(date).format(format)}
                      </h1> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }
          )}
        </div>
      </div>
    </>
  )
}
