import Banglore from "../images/banglore.jpg"
import ChandigarhImage from "../images/chandigarh.jpg"
import GuwahatiImage from "../images/Guwahati.jpg"
import GoaImage from "../images/goa.jpg"
import AurovilleImage from "../images/auroville-pondicherry.jpg"
import Delhi2Image from "../images/Delhi.jpg"
import Vikrantimg from "../images/newDesign/Vikrant1.jpg"
import Puneetaimg from "../images/newDesign/Puneeta1.jpg"
import Sanjayimg from "../images/newDesign/Sanjay1.jpg"
import a48 from "../images/newDesign/speakers/a48.png"
import b49 from "../images/newDesign/speakers/b49.png"
import c50 from "../images/newDesign/speakers/c50.png"
import d51 from "../images/newDesign/speakers/d51.png"
import e52 from "../images/newDesign/speakers/e52.png"
import f53 from "../images/newDesign/speakers/f53.png"
import g54 from "../images/newDesign/speakers/g54.png"
import i55 from "../images/newDesign/speakers/i55.png"
import k56 from "../images/newDesign/speakers/k56.png"
import l57 from "../images/newDesign/speakers/l57.png"
import m58 from "../images/newDesign/speakers/m58.png"
import m59 from "../images/newDesign/speakers/m59.png"
import m60 from "../images/newDesign/speakers/m60.png"
import m61 from "../images/newDesign/speakers/m61.png"
import m62 from "../images/newDesign/speakers/m62.png"
import m63 from "../images/newDesign/speakers/m63.png"
import m64 from "../images/newDesign/speakers/m64.png"
import m65 from "../images/newDesign/speakers/m65.png"
import m66 from "../images/newDesign/speakers/m66.png"
import m67 from "../images/newDesign/speakers/m67.png"
import m68 from "../images/newDesign/speakers/m68.png"
import m69 from "../images/newDesign/speakers/m69.png"
import m70 from "../images/newDesign/speakers/m70.png"
import m71 from "../images/newDesign/speakers/m71.png"
import m72 from "../images/newDesign/Sponsors_logos/m72.png"
import m73 from "../images/newDesign/Sponsors_logos/m73.png"
import m74 from "../images/newDesign/Sponsors_logos/m74.png"
import m75 from "../images/newDesign/Sponsors_logos/m75.png"
import m76 from "../images/newDesign/Sponsors_logos/m76.png"
import m77 from "../images/newDesign/Sponsors_logos/m77.png"
import m78 from "../images/newDesign/Sponsors_logos/m78.png"
import m79 from "../images/newDesign/Sponsors_logos/m79.png"
import m80 from "../images/newDesign/Sponsors_logos/m80.png"
import m81 from "../images/newDesign/Sponsors_logos/m81.png"
import m82 from "../images/newDesign/Sponsors_logos/m82.png"
import Glimses1 from "../images/newDesign/Glimses1.jpg"
import Glimses2 from "../images/newDesign/Glimses2.jpg"
import Glimses3 from "../images/newDesign/Glimses3.jpg"
import Glimses4 from "../images/newDesign/Glimses4.jpg"
import Glimses5 from "../images/newDesign/Glimses5.jpg"
import Glimses6 from "../images/newDesign/Glimses6.jpg"




const record = (
  link,
  city,
  location,
  datetime,
  eventName,
  image,
  highlight
) => ({
  link,
  city,
  location,
  datetime,
  eventName,
  image,
  highlight,
})

export const events = [
  // record(
  //   "New Delhi",
  //   "India Habitat Center, New Delhi",
  //   "2023-03-16T00:00:00",
  //   "Indian ESG Network Launch & National Conclave (2022-23)",
  //   Delhi1Image
  // ),
  record(
    "/delhi-programs",
    "New Delhi",
    null,
    { timestamp: "2024-02-16T00:00:00", format: "MMMM, YYYY" },
    "Silver Oak, Indian Habitat Centre, New Delhi, INDIA, 03 May, 2024, Time: 10:00 AM - 4:30 PM",
    Delhi2Image,
    "blink_me"
  ),
  record(
    "/auroville-programs",
    "Puducherry",
    'Auroville "City of Dawn"…Visit the Spirit of Sustainability (8th Dec  - 9th Dec 2023) - 2 days ESG Retreat',
    { timestamp: "2024-01-16T00:00:00", format: "MMMM, YYYY" },
    null,
    AurovilleImage,
    null
  ),
  record(
    null,
    "Bangalore",
    "South East",
    { timestamp: "2023-05-16T00:00:00", format: "MMMM, YYYY" },
    null,
    Banglore,
    null
  ),
  record(
    null,
    "Chandigarh",
    "North",
    { timestamp: "2023-07-16T00:00:00", format: "MMMM, YYYY" },
    null,
    ChandigarhImage,
    null
  ),
  record(
    null,
    "Guwahati",
    "North East",
    { timestamp: "2023-09-16T00:00:00", format: "MMMM, YYYY" },
    null,
    GuwahatiImage,
    null
  ),
  record(
    null,
    "Goa",
    "West",
    { timestamp: "2023-11-16T00:00:00", format: "MMMM, YYYY" },
    null,
    GoaImage,
    null
  ),
]


export const KeyTakeaways = [
  { image: Vikrantimg,
    heading: "Vikrant Abrol, IESGN Founder",
    para:"Vikrant Abrol, IESGN Founder, came forward and mentioned importance of a network that work with various corporate for achieving SDGs.Also, he urged everyone to bring ideas for betterment of society from boardroom to ground level which would make environment a better place to live in. He mentioned importance of ESG's social aspect as well as role of CSC in sustainability through various pilot projects."
  },
    {
      image: Puneetaimg,
    heading:"Puneeta Puri, Executive Director of IESGN ",
    para:" Explained social aspect of SDGs. She also differentiated ESG from SDG, where ESG is a wider perspective and it is now adopted by corporate in corporate social responsibility (CSR). She also appreciated role of CSC and its projects that are fulfilling motives of different SDG and ESG goals."
  },
  { image: Sanjayimg,
    heading:"Sanjay Rakesh, Chairman and Secretary of CSC Academy",
    para:"Emphasized the significant role of digital platforms in enhancing education and skilling at the rural level while giving the keynote address. He also mentioned the CSC's commitment towards sustainability through various initiatives such as Bal Vidyalaya, Digital Vans, Stree Swabhiman, and many more. He also emphasised on the development of Digital Library and empowering Anganwadi centres with technology that will help in reducing urban migration and empowering local communities."
  }, 
 
]

export const speakers = [
  {
    image :m58,
    heading : "Vikrant Abrol",
    para: "Founder, Indian ESG Netwrok"
  },
  {
    image :m59,
    heading : "Sanjay Rakesh",
    para: "Chairman & Secretary, CSC Academy"
  },
  {
    image :m66,
    heading : "Puneeta Puri",
    para: "Executive Director,Indian ESG Network"
  },
  {
    image :m60,
    heading : "Atul Kumar Tiwari",
    para: "Secretary, Ministry of Skill Development and Entrepreneurship"
  },
  {
    image :m61,
    heading : "S. Krishnan",
    para: "Secretary, Ministry of Electronics and Information Technology"
  },

  {
    image: a48,
    heading: "Sudeep Dubey",
    para: "Senior Vice President Learning Links Foundation"
  },
  {
    image:b49,
    heading: "Nishant Chadha",
    para: "Director, Policy and Research Indian School of Business"

  },
  {
    image :c50,
    heading : "Archi Sharma",
    para: "AVP, CSC SPV"
  },
  {
    image :d51,
    heading : "Vivek Kumar",
    para: "CSC Academy"
  },
  {
    image :e52,
    heading : "Mary Rupa Tete",
    para: "Vice President, USHA"
  },
  {
    image :f53,
    heading : "Mary Rupa Tete",
    para: "Vice President, USHA"
  },
  {
    image :g54,Glimses1,
    heading : "Rajiv Malik",
    para: "CEO, Graposs Connect"
  },
  {
    image :i55,
    heading : "Priya Naik",
    para: "Founder, Samhita"
  },
  {
    image :k56,
    heading : "Lay Smrity",
    para: "VP, CSC SPV"
  },
  {
    image :l57,
    heading : "Jyoti Kumar",
    para: "Professor, IIT Delhi"
  },
 

  {
    image :m62,
    heading : "Pradeep Singh",
    para: "Head - Monitoring and Impact, HDFC Bank CSR"
  },
  {
    image :m63,
    heading : "Vidushi Saxena",
    para: "Adobe"
  },
  {
    image :m64,
    heading : "Akshay Dhoot",
    para: "Founder and Director, Smartping"
  },
  {
    image :m65,
    heading : "Dinesh Agrawal",
    para: "Principal Advisor Consocia Advisory (CSR Act)"
  },
 
  {
    image :m67,
    heading : "Praveen Dwivedi",
    para: "Senior Vice President,Public Sector & Public Affairs,Wheebox ETS"
  },
  {
    image :m68,
    heading : "Pratik Kumar",
    para: "CEO, Sashakt Foundation"
  },
  {
    image :m69,
    heading : "Vignesh Sornamohan",
    para: "SVP, CSC SPV"
  },
  {
    image :m70,
    heading : "Girija Mukund",
    para: "Director-Corporate Social Responsibility and ESG,Kyndryl"
  },
  {
    image :m71,
    heading : "Sanjeev Sehgal",
    para: "MD and Founder of Sparsh"
  },
]


export const newsArticles = [

  {
    dop: "4 May 2024",
    heading: "Economic Times",
    para:" Empowering sustainable development: CSC-IESGN SDG conclave promotes ESG awareness",
    link: "https://government.economictimes.indiatimes.com/news/governance/empowering-sustainable-development-csc-iesgn-sdg-conclave-promotes-esg-awareness/109837785?utm_source=latest_news&utm_medium=homepage    ",
  },
  {
    dop: "5 May 2024",
    heading: "Rural Voice",
    para:" Empowering sustainable development: CSC-IESGN SDG conclave promotes ESG awareness",
    link: "https://eng.ruralvoice.in/national/empowering-sustainable-development-csc-iesgn-sdg-conclave-promotes-esg-awareness.html",
  },
  {
    dop: "4 May 2024",
    heading: "PNI News",
    para:" CSC's growing steps towards rural empowerment and sustainable development",
    link: "https://pninews.in/CSC-growing-steps-towards-rural-empowerment-and-sustainable-development ",
  },
  {
    dop: "4 May 2024",
    heading: "Krishi Jagran",
    para:" Empowering sustainable development: CSC-IESGN SDG conclave promotes ESG awareness",
    link: "https://krishijagran.com/news/empowering-sustainable-development-csc-iesgn-sdg-conclave-promotes-esg-awareness/",
  },
  {
    dop: "4 May 2024",
    heading: "The Indian Community",
    para:" Two-thirds of Indian companies are striving for better implementation of SDG goals",
    link: "https://www.theindiancommunity.org/news/two-thirds-of-indian-companies-are-striving-for-better-implementation-of-sdg-goals.27637/",
  },
  {
    dop: "4 May 2024",
    heading: "Sandhya Samay News",
    para:" सीएससी-आईईएसजीएन एसडीजी कॉन्क्लेव: सतत विकास की दिशा में एक महत्वपूर्ण कदम",
    link: "https://sandhyasamaynews.co.in/csc-iesgn-sdg-conclave-an-important-step-towards-sustainable-development/",
  },
  {
    dop: "4 May 2024",
    heading: "Daijiworld.com",
    para:" Two-thirds of Indian companies are striving for better implementation of SDG goals",
    link: " https://daijiworld.com/news/newsDisplay?newsID=1188785",
  },
  {
    dop: "4 May 2024",
    heading: "You Change",
    para:" CSC-IESGN SDG conclave promotes ESG awareness",
    link: " https://you-change-com.eu/foodsafe/tic55924k1d11/",
  },
  {
    dop: "5 May 2024",
    heading: "Jawab Do Sarkar",
    para:" CSC-IESGN SDG कॉन्क्लेव: सतत विकास की दिशा में एक महत्वपूर्ण कदम",
    link: " https://jababdosarkar.blogspot.com/2024/05/csc-iesgn-sdg.html",
  },

]


export const sponsors = [
  {
    image: m72
  },
  {
    image: m73
  },
  {
    image: m74
  },
  {
    image: m75
  },
  {
    image: m76
  },
  {
    image: m77
  },
  {
    image: m78
  },
  {
    image: m79
  },
  {
    image: m80
  },
  {
    image: m81
  },
  {
    image: m82
  },

  
]

export const eventVideo = [
  {
    url : "https://www.youtube.com/embed/a5l2dMw6UiM?si=881HV3xEtvZAdcJK",
  },
  {
    url: "https://www.youtube.com/embed/BKBlfk0kFXg?si=M4G2eRUvQr1Pn7IN",
  }
]


export const glimpse =[ 
  {
    image: Glimses1
  },
  {
    image: Glimses2
  },
  {
    image: Glimses3
  },
  {
    image: Glimses4
  },
  {
    image: Glimses5
  },
  {
    image: Glimses6
  },
]

export const indmediaCoverage = [
  {
    heading:"Electrinic maker",
    para: "Indian ESG Network and ASDC Host Successful ESG Conclave to Promote Sustainable Development",
    url: "https://electronicsmaker.com/indian-esg-network-and-asdc-host-successful-esg-conclave-to-promote-sustainable-development"
  },
  
  {
    heading:"education diary.com",
    para: "ESG Conclave To Promote Sustainable Development",
    url: "https://indiaeducationdiary.in/indian-esg-network-and-asdc-host-successful-esg-conclave-to-promote-sustainable-development-2/"
  },
  {
    heading:"Mobility outlook",
    para: "ASDC, Indian ESG Network Stress The Need For Sustainable Future",
    url: "https://www.mobilityoutlook.com/news/asdc-indian-esg-network-stress-the-need-for-sustainable-future/"
  },
  
  {
    heading:"Sia India",
    para: "Indian ESG Network Launch & National Conclave (2022-23)",
    url: "https://www.sia-india.com/indian-esg-network-launch-national-conclave-2022-23/"
  },
  
]

export const rusediaCoverage = [
  {
    heading:"timestech.in",
    para:"The Indian ESG Network, in collaboration with Gazprombank and Druzhba Dosti Forum",
    url:"https://timestech.in/sustainability-explored-at-india-russia-virtual-roundtable-on-esg/",
  },

  {
    heading:"Bisinfotech",
    para:"India-Russia Virtual Roundtable on Sustainability",
    url:"https://www.bisinfotech.com/india-russia-virtual-roundtable-on-sustainability/",
  },
  {
    heading:"The education post",
    para:"Journey Towards Sustainability Explored at India-Russia Virtual Roundtable",
    url:"https://theeducationpost.in/journey-towards-sustainability-explored-at-india-russia-virtual-roundtable-on-esg/",
  },
  {
    heading:"Electronic Media",
    para:"Milestone in the global movement towards sustainability,",
    url:"https://www.electronicsmedia.info/2023/06/12/indian-esg-network-sustainability-goals/",
  },
]

export const cscCoverage =[
  {

    heading:"krishijagran.com",
    para:"Empowering Sustainable Development: CSC-IESGN SDG Conclave Promotes ESG Awareness",
    url:"https://krishijagran.com/news/empowering-sustainable-development-csc-iesgn-sdg-conclave-promotes-esg-awareness/",
  },

  {

    heading:"Economictimes",
    para:"CSC-IESGN SDG Conclave Promotes ESG Awareness",
    url:"https://government.economictimes.indiatimes.com/news/governance/empowering-sustainable-development-csc-iesgn-sdg-conclave-promotes-esg-awareness/109837785",
  },
  {

    heading:"daijiworld.com",
    para:"Two-thirds of companies in India are striving for better execution",
    url:"https://www.daijiworld.com/index.php/news/newsDisplay?newsID=1188785",
  },
  {

    heading:"krishijagran.com",
    para:"‘ग्रामीण सशक्तिकरण और सतत विकास’ की तरफ सीएससी के बढ़ते कदम, ESG से जुड़े प्रमुख विषयों पर की गई चर्चा",
    url:"https://hindi.krishijagran.com/news/csc-iesgn-sdg-conclave-rural-india-continuous-development-india-habitat-center-new-delhi/",
  },
  {

    heading:"lokmattimes.com",
    para:"Companies striving for better execution of SDG goals'",
    url:"https://www.lokmattimes.com/technology/two-thirds-of-indian-companies-striving-for-better-execution-of-sdg-goals-1",
  },
  {

    heading:"Bhaskarlive",
    para:"‘Two-thirds of Indian companies striving for better execution of SDG goals’",
    url:"https://bhaskarlive.in/two-thirds-of-indian-companies-striving-for-better-execution-of-sdg-goals/",
  },
]


